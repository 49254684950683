import type { Currency } from "~/types";

// TODO: account for special cases (UGX / RSD / etc)
export function currencyInfo(currency: Currency, locale = "en") {
  if (!currency) return { separator: "", decimal: 2, decimalPlaces: 2, symbol: "" };
  const parts = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).formatToParts(1000);

  let separator;
  let decimal;
  let decimalPlaces = 0;
  let symbol;
  for (const p of parts) {
    switch (p.type) {
      case "group":
        separator = p.value;
        break;
      case "decimal":
        decimal = p.value;
        break;
      case "fraction":
        decimalPlaces = p.value.length;
        break;
      case "currency":
        symbol = p.value;
        break;
      default:
      // code block
    }
  }
  const info = { separator, decimal, decimalPlaces, symbol };
  if (currency === "RSD" || currency === "ISK") info.decimalPlaces = 2;
  return info;
}
