import type { Currency } from "~/types";

import { currencyInfo } from "./currency-info";

function unitToStandard(unitValue: number, currency: Currency) {
  return unitValue / 10 ** currencyInfo(currency).decimalPlaces;
}

function toCurrencyDecimals(value: number, currency: Currency): string {
  const fraction = Intl.NumberFormat("en", {
    style: "currency",
    currency,
  })
    .formatToParts(1)
    .find((part) => part.type === "fraction");

  let decimalPlaces;
  if (currency === "RSD" || currency === "ISK") {
    decimalPlaces = 2;
  }
  decimalPlaces = fraction ? fraction.value.length : 0;
  return (+value).toFixed(decimalPlaces);
}

export function parsePrice(price: number, currency: Currency): string {
  return toCurrencyDecimals(unitToStandard(price, currency), currency);
}
