import countryData from "country-data";

import { PRESENTMENT_CURRENCIES } from "~/constants/currencies";
import type { Currency, CurrencyData, Locale, PreferredCurrency } from "~/types";

import { currencyInfo } from "./currency-info";

//**
//  *
//  * @param {*} locale {locale: 'en-US', countryCode: 'US', languageCode: 'en'}
//  * @param {Boolean} required true if leave out NA Not Listed/Don't Convert Prices
//  * @returns [{code: 'USD', symbol: '$', name: 'United States dollar', match: true},
// {code: 'noConversion', symbol: 'N/A', name: 'Not Listed/Don't Convert Prices'}.
// {code: 'ARS', symbol: 'ARS', name: 'Argentine peso'}]
//  */

export function getCurrencyList<T extends boolean>(
  locale?: Locale,
  required?: T,
): CurrencyData<T extends true ? Currency : PreferredCurrency>[] {
  let matchingCurrencies = locale ? countryData.countries[locale.countryCode]?.currencies : [];
  if (!matchingCurrencies) matchingCurrencies = [];

  let filteredAPICurrencies = PRESENTMENT_CURRENCIES.filter(
    (current) => !matchingCurrencies.includes(current),
  );
  const matchingCurrenciesData = matchingCurrencies
    .map((current) => ({
      code: current,
      symbol: currencyInfo(current as Currency).symbol,
      name: countryData.currencies[current].name,
      match: true,
    }))
    .sort((a, b) => (a.name[0] > b.name[0] ? 1 : -1));

  const filteredAPICurrenciesData = filteredAPICurrencies
    .map((current) => ({
      code: current,
      symbol: currencyInfo(current as Currency).symbol,
      name: countryData.currencies[current].name,
    }))
    .sort((a, b) => (a.name[0] < b.name[0] ? -1 : 1));

  return [
    ...matchingCurrenciesData,
    ...(required
      ? []
      : [
          {
            code: "noConversion",
            symbol: "N/A",
            name: "Not Listed/Don't Convert Prices",
          },
        ]),
    ...filteredAPICurrenciesData,
  ] as CurrencyData<T extends true ? Currency : PreferredCurrency>[];
}

export function getPreferredCurrencyList<T extends boolean>(
  locale?: Locale,
  required?: T,
): CurrencyData<T extends true ? Currency : PreferredCurrency>[] {
  let localeCurrencies = locale ? countryData.countries[locale.countryCode]?.currencies : [];
  if (!localeCurrencies) localeCurrencies = [];

  const localeCurrenciesData = localeCurrencies
    .map((current) => ({
      code: current,
      symbol: currencyInfo(current as Currency).symbol,
      name: countryData.currencies[current].name,
      match: true,
    }))
    .sort((a, b) => (a.name[0] > b.name[0] ? 1 : -1));

  return [
    ...localeCurrenciesData,
    ...(required
      ? []
      : [
          {
            code: "noConversion",
            symbol: "N/A",
            name: "Not Listed/Don't Convert Prices",
          },
        ]),
  ] as CurrencyData<T extends true ? Currency : PreferredCurrency>[];
}
