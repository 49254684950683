// currencies that our exchange rate api and stripe allow for checkout. more can probably be added
export const PRESENTMENT_CURRENCIES = [
  "AED",
  "ARS",
  "AUD",
  "BGN",
  "BOB",
  "BRL",
  "CAD",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CZK",
  "DKK",
  "DOP",
  "EGP",
  "EUR",
  "GBP",
  "HKD",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "ISK",
  "JPY",
  "KES",
  "KRW",
  "MAD",
  "MKD",
  "MXN",
  "MYR",
  "NGN",
  "NOK",
  "NZD",
  "PEN",
  "PHP",
  "PLN",
  "PYG",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SEK",
  "SGD",
  "THB",
  "TRY",
  "TTD",
  "USD",
  "UYU",
  "VND",
  "XCD",
  "ZAR",
] as const;

export const PLATFORM_CURRENCY = "USD";
