import type { Currency } from "~/types";

import { useLocale } from "../../../hooks/use-locale";
import { currencyInfo } from "../utils/currency-info";

export function useFormatCurrency(currency: Currency, locale?: string) {
  const { locale: currentLocale } = useLocale();
  const decimalPlaces = currencyInfo(currency).decimalPlaces;
  return (
    amount: number,
    options?: { style?: "compact"; compactThreshold?: number; decimalPlacesThreshold?: number },
  ) => {
    const value = amount / Math.pow(10, decimalPlaces);

    // Remove decimal places if the value is greater than the threshold
    const decimalPlacesThreshold = options?.decimalPlacesThreshold ?? Number.POSITIVE_INFINITY;
    const maximumFractionDigits = value < decimalPlacesThreshold ? undefined : 0;

    const compactThreshold = options?.compactThreshold ?? 1000;
    // Don't use compact notation for small values (<1000)
    // Because 100.5 will be compacted to 101, we want to keep the decimal places on small values.
    const notation =
      options?.style === "compact" && value < compactThreshold ? "standard" : options?.style;

    return new Intl.NumberFormat(locale || currentLocale, {
      style: "currency",
      currency,
      notation,
      maximumFractionDigits,
    }).format(value);
  };
}
