import type { Currency } from "~/types";

import { currencyInfo } from "./currency-info";

export function createCurrencyConverter({
  from,
  to,
  exchangeRate,
}: {
  from: Currency;
  to: Currency;
  exchangeRate: number;
}) {
  if (from === to) {
    return (value: number) => value;
  }

  const fromDP = currencyInfo(from).decimalPlaces;
  const toDP = currencyInfo(to).decimalPlaces;

  function convert(fromValue: number) {
    return Number(fromValue * exchangeRate * Math.pow(10, toDP - fromDP));
  }

  return convert;
}
