import type { Currency } from "~/types";

function toDotDecimal(price: string) {
  return Number.parseFloat(price.replace(/([,.])(\d{3})/g, `$2`).replace(/(,|\.)/, "."));
}
export function toSmallestUnit(price: string, currency: Currency): string {
  // hotfix for RSD bug in JS --> should be treated as 2-decimal
  if (currency === "RSD" || currency === "ISK") {
    return String(Math.round(Number.parseFloat(price) * 100));
  }

  const dotDec = toDotDecimal(price);
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency,
  })
    .formatToParts(dotDec)
    .reduce((a, c) => {
      if (c.type === "integer" || c.type === "fraction") return a + c.value;
      return a;
    }, "");
}
