import type { Currency } from "~/types";

import { usePreferredCurrency } from "./use-preferred-currency";

export function usePresentmentCurrency(defaultCurrency: Currency): Currency {
  const currency = usePreferredCurrency();
  if (currency === "noConversion") {
    return defaultCurrency;
  }
  return currency;
}
